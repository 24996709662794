<template>

  <div>

    <div class="item__row item__ac">

      <h2>Адрес</h2>

      <v-btn
          small
          class="mx-2"
          fab
          dark
          color="#1f2b45"
          @click="chooseTypeFunction(1)"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
      </v-btn>

    </div>

    <div class="item__column  pa-4 mb-2 news__list" v-for="item in items" :key="item.id">

      <p class="mb-2">Город на рус: {{ item.city }}</p>
      <p class="mb-2">Город на каз: {{ item.city_kaz }}</p>
      <p class="mb-2">Город на анг: {{ item.city_eng }}</p>


      <p class="mb-2">Адрес на рус: {{ item.address }}</p>
      <p class="mb-2">Адрес на каз : {{ item.address_kaz }}</p>
      <p class="mb-2">Адрес на анг : {{ item.address_eng }}</p>

      <v-divider></v-divider>
      <div class="item__row item__ac">
        <v-btn
            small
            class="mx-2 mr-2"
            fab
            dark
            color="#1f2b45"
            @click="show(item)"
        >
          <v-icon dark>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
            v-if="me && me.role.role == 'admin'"
            small
            class="mx-2 mr-2"
            fab
            dark
            @click="openDeleteModal(item.id)"
            color="#1f2b45"
        >
          <v-icon dark>
            mdi-trash-can-outline
          </v-icon>
        </v-btn>
      </div>

    </div>
    <v-dialog v-model="destroyModal" width="750">
      <v-card class="pa-6">
        <h3 class="mb-4">Удалить запись</h3>
        <v-btn
            type="submit"
            depressed
            color="primary"
            @click="deleteItem()"
        >
          Да
        </v-btn>

        <v-btn
            depressed
            color="default"
            @click="destroyModal=false"
        >
          Отмена
        </v-btn>
      </v-card>
    </v-dialog>

    <v-dialog v-model="newsModal" width="750">
      <v-card class="pa-6">
        <v-form
            @submit.prevent="callFunction()"
            ref="form"
            class="sign__page__block"
        >

          <h3 class="mb-4" v-if="type==1">Создать </h3>
          <h3 class="mb-4" v-else>Редактировать </h3>
          <div class="item__column">
            <v-text-field
                v-model="title"
                label="Город"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>
          <div class="item__column">
            <v-text-field
                v-model="description"
                label="Улица"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>


          <div class="item__column">
            <v-text-field
                v-model="title_kaz"
                label="Город на каз"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>
          <div class="item__column">
            <v-text-field
                v-model="description_kaz"
                label="Улица на каз"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>

          <div class="item__column">
            <v-text-field
                v-model="title_eng"
                label="Город на анг"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>
          <div class="item__column">
            <v-text-field
                v-model="description_eng"
                label="Улица на анг"
                required
                outlined
                class="input"
                :rules="nameRules"
            ></v-text-field>
          </div>



          <v-btn
              type="submit"
              depressed
              color="primary"
              class="mr-2"
          >
            Сохранить изменения
          </v-btn>

          <v-btn
              depressed
              color="default"
              @click="newsModal=false"
          >
            Отмена
          </v-btn>
        </v-form>



      </v-card>
    </v-dialog>

  </div>

</template>

<script>

export default {
  name: "News",
  data() {
    return {
      items: [],
      newsModal: false,
      destroyModal: false,
      title: '',
      description: '',
      title_kaz: '',
      description_kaz: '',
      title_eng: '',
      description_eng: '',
      nameRules: [
        v => !!v || 'Заполните поле'
      ],
      descriptionRules: [
        v => !!v || 'Заполните поле'
      ],
      files: [],
      type: 0,
      newsId:'',
      me: null,
      selectedUser: null,
    };
  },
  methods: {
    getUser() {
      this.$axios({
        method: "get",
        url:
            this.$API_URL +
            this.$API_VERSION +
            "me",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((response) => {
            this.me = response.data;
          })
          .catch((error) => {
            console.warn(error);
          });
    },
    chooseTypeFunction(type) {
      this.type = type;
      this.newsModal = true;
    },
    callFunction() {
      this.type==1?this.create():this.update();
    },
    openDeleteModal(item) {
      this.destroyModal = true;
      this.idItem = item;
    },
    create() {
      let contractForm = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        contractForm.append("file[]", this.files[i]);
      }

      contractForm.append("city", this.title);
      contractForm.append("address", this.description);


      contractForm.append("city_kaz", this.title_kaz);
      contractForm.append("address_kaz", this.description_kaz);


      contractForm.append("city_eng", this.title_eng);
      contractForm.append("address_eng", this.description_eng);
      this.$axios
          .post(this.$API_URL + this.$API_VERSION + "address/store", contractForm, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.files = [];
            this.title = null;
            this.description = null;
            console.log(response);
            this.$toast.open({
              message: "Успешно создано",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });

            this.newsModal = false;
            this.type = 0;
            this.$refs.form.reset();
            this.fetch();

          })
          .catch((error) => {
            if (error.response && error.response.status == 422) {
              this.$toast.open({
                message: "Заполните все поля",
                type: "error",
                position: "bottom",
                duration: 4000,
                queue: true,
              });

            }
          });
    },
    update() {
      this.$axios
          .put(this.$API_URL + this.$API_VERSION + "address/"+this.idItem,
              {
                city: this.title,
                city_kaz: this.title_kaz,
                city_eng: this.title_eng,

                address: this.description,
                address_kaz: this.description_kaz,
                address_eng: this.description_eng,
              
              }, {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("access_token")}`,
                },
              })
          .then((response) => {

            this.title = null;
            this.description = null;
            console.log(response);
            this.$toast.open({
              message: "Успешно обновлено",
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });

            this.newsModal = false;

            this.fetch();
          })
          .catch((error) => {
            if (error.response && error.response.status == 422) {
              this.$toast.open({
                message: "Заполните все поля",
                type: "error",
                position: "bottom",
                duration: 4000,
                queue: true,
              });

            }
          });
    },
    fetch() {
      this.$axios({
        method: "get",
        url:
            this.$API_URL +
            this.$API_VERSION +
            "address",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((response) => {
            this.items = response.data;
            console.log(this.items);
          })
          .catch((error) => {
            console.log(error);
          });
    },

    show(item) {
      this.idItem = item.id;
      this.title = item.city;
      this.description = item.address;
      this.title_kaz = item.city_kaz;
      this.description_kaz = item.address_kaz;
      this.title_eng = item.city_eng;
      this.description_eng = item.address_eng;
      this.newsModal = true;
    },
    deleteItem() {
      this.$axios({
        method: "delete",
        url:
            this.$API_URL +
            this.$API_VERSION +
            "address/"+this.idItem,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
          .then((response) => {
            this.title = response.data.city;
            this.description = response.data.address;
            this.fetch();
            this.destroyModal = false
          })
          .catch((error) => {
            console.log(error);
          });
    },
  },

  mounted() {
    this.fetch();
    this.getUser();
  },
  beforeMount() {

  },
  watch: {
    newsModal(val) {
      if(val==false) {
          this.idItem = '';
          this.title = '';
          this.description = '';
          this.title_kaz = '';
          this.description_kaz = '';
          this.title_eng = '';
          this.description_eng = '';
          this.$refs.form.reset();
      }
    }
       
  },
};
</script>


<style lang="scss">
.news__list {
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
</style>
